// ========== imports ========== //
import "./NavbarHome.css";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RxHamburgerMenu, RxCross1 } from "react-icons/rx";
import { BsInstagram, BsTwitter, BsLinkedin } from "react-icons/bs";

import { Link } from "react-router-dom";
// ========== component ========== //
function NavbarHome({ aboutMe }) {
  // ========== hook consts ========== //

  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);
  const breakpoint = 1024;
  // ========== other consts ========== //

  const routes = [
    {
      route: "/Home",
      component: "/Home",
      key: "/Home",
      title: "Home",
    },
    {
      route: "/About",
      component: "/AboutPage",
      key: "/About",
      title: "About",
    },
    {
      route: "/Services",
      component: "/Services",
      key: "/Services",
      title: "Services",
    },
    {
      route: "/Blog",
      component: "/Blog",
      key: "/Blog",
      title: "Blog",
    },
    {
      route: "/The-Guarantor-Scheme",
      component: "/TheGuarantorScheme",
      key: "/TheGuarantorScheme",
      title: "The Guarantor Scheme",
    },
    {
      route: "/Associates",
      component: "/Associates",
      key: "/Associates",
      title: "Associates",
    },
    {
      route: "/Contact",
      component: "/Contact",
      key: "/Contact",
      title: "Contact",
    },
  ];

  // ========== jsx ========== //
  return (
    <nav
      className={`nav collapsible ${isActive ? "collapsible--expanded" : null}`}
    >
      <div className="nav__bar">
        {width < breakpoint && (
          <div className="nav__toggler-container">
            <div className="logo">
              <Link to="/home">
                <img
                  className="logo"
                  src="https://mary-anne-hodd.s3.eu-west-2.amazonaws.com/base/logo-green-round.png"
                  alt=""
                />
              </Link>
            </div>
            <div className="nav__icons--mobile">
              {!isActive ? (
                <RxHamburgerMenu
                  className="icon nav__toggler"
                  onClick={() => setIsActive(!isActive)}
                />
              ) : (
                <RxCross1
                  className="icon nav__toggler"
                  onClick={() => setIsActive(!isActive)}
                />
              )}

              <div>
                <a
                  href="https://twitter.com/maryhodd"
                  title="Follow us on Twitter (opens new tab)"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <BsTwitter className="nav__media-icon" />
                </a>
                <a
                  href="https://www.linkedin.com/in/mary-anne-hodd-376780105/"
                  title="Follow us on LinkedIn (opens new tab)"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <BsLinkedin className="nav__media-icon" />
                </a>
                <a
                  href="https://www.instagram.com/maryannehodd/"
                  title="Follow us on Instagram (opens new tab)"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <BsInstagram className="nav__media-icon" />
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
      <ul
        className={`list nav__list ${
          width < breakpoint ? "collapsible__content" : null
        }`}
      >
        {routes.map((element) => {
          return (
            <li
              className={`nav__item ${
                element.title === "Services" ? "services-nav-item" : null
              } ${element.title === "Associates" ? "services-nav-item" : null}`}
              key={element.key}
              onClick={() => width < breakpoint && setIsActive(!isActive)}
            >
              <button
                onClick={() =>
                  navigate(`${element.route.slice(1).toLowerCase()}`)
                }
              >
                {element.title}
              </button>
              {element.title === "Services" ? (
                <ul className="dropdown">
                  <li>
                    <button onClick={() => navigate(`/training`)}>
                      Training
                    </button>
                  </li>
                  <li>
                    <button onClick={() => navigate(`/keynotes`)}>
                      Keynotes
                    </button>
                  </li>
                  <li>
                    <button onClick={() => navigate(`/consultancy`)}>
                      Consultancy
                    </button>
                  </li>
                </ul>
              ) : null}
              {element.title === "Associates" ? (
                <ul className="dropdown">
                  <li>
                    <button onClick={() => navigate(`/afit`)}>AFIT</button>
                  </li>
                </ul>
              ) : null}
            </li>
          );
        })}
      </ul>
    </nav>
  );
}

export default NavbarHome;
